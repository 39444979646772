import React, { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa6";
import PropTypes from "prop-types";

// Caching function with size management
const fetchIPDetails = (() => {
  const cache = {};
  const CACHE_EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes
  const MAX_CACHE_ENTRIES = 100;

  return async function (ip) {
    const currentTime = Date.now();

    // Check for cached data
    if (
      cache[ip] &&
      currentTime - cache[ip].timestamp < CACHE_EXPIRATION_TIME
    ) {
      return cache[ip].data;
    }

    // Fetch new data
    try {
      const response = await fetch(`https://ipwho.is/${ip}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch data for IP: ${ip}`);
      }
      const data = await response.json();

      // Add to cache and maintain size limit
      cache[ip] = { data, timestamp: currentTime };
      if (Object.keys(cache).length > MAX_CACHE_ENTRIES) {
        delete cache[Object.keys(cache)[0]]; // Remove oldest entry
      }

      return data;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  };
})();

const IPColumn = ({ ip }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      setHasError(false);

      try {
        const data = await fetchIPDetails(ip);
        if (isMounted) {
          setImageUrl(data?.flag?.img); // Update with API's image URL field
          setCountry(data?.country);
        }
      } catch (error) {
        if (isMounted) {
          setHasError(true);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [ip]);

  return (
    <>
      {isLoading ? (
        <span>
          <FaSpinner />
        </span>
      ) : hasError ? (
        <span>Error loading data</span>
      ) : imageUrl ? (
        <img width={20} src={imageUrl} alt={`${ip}`} title={country} />
      ) : (
        <span>No image available</span>
      )}
    </>
  );
};

IPColumn.propTypes = {
  ip: PropTypes.string.isRequired,
};

export default IPColumn;
