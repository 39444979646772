import { Button, Checkbox, Form, Input, notification } from "antd";
import REQUESTS from "../../../api/requests";
import { useEffect, useState } from "react";
import ErrorMessage from "../../../components/ErrorMessage";

export default function BackupPlaylist() {
  const [form] = Form.useForm();

  const [playlist, setPlaylist] = useState({});

  const [message, setMessage] = useState({
    type: false,
    text: "",
  });

  const getBackupPlaylist = () => {
    function callback(data) {
      setPlaylist(data);
    }
    function errorCallback() {}

    REQUESTS.BACKUP_PLAYLIST.GET(callback, errorCallback);
  };

  const onFinish = (values) => {
    setMessage({ type: false, text: "" });

    const { is_enabled, url } = values;

    const body = { is_enabled, url: url || null, id: playlist.id };

    function callback(data) {
      notification.open({
        message: "Updated",
        duration: 5,
      });

      getBackupPlaylist();
    }

    function errorCallback(error) {
      setMessage({ type: false, text: error });
    }

    REQUESTS.BACKUP_PLAYLIST.PUT(body, callback, errorCallback);
  };

  useEffect(() => {
    getBackupPlaylist();
  }, []);

  useEffect(() => {
    form.setFields([
      {
        name: "url",
        value: playlist.url,
      },
      {
        name: "is_enabled",
        value: playlist.is_enabled,
      },
    ]);
  }, [playlist]);

  return (
    <div>
      <div>
        <h3 className="google-ima-title">Backup playlist</h3>
        <Form form={form} onFinish={onFinish} style={{ maxWidth: 300 }}>
          <Form.Item name="url">
            <Input placeholder="URL" />
          </Form.Item>
          <Form.Item name="is_enabled" valuePropName="checked">
            <Checkbox>Enable</Checkbox>
          </Form.Item>
          <ErrorMessage message={message} />
          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div>
        <h3 className="google-ima-title">Xtream-Masters configurations</h3>
        <Form form={form} onFinish={onFinish} style={{ maxWidth: 300 }}>
          <Form.Item name="url" label="Api-Key:">
            <Input placeholder="URL" />
          </Form.Item>
          <ErrorMessage message={message} />
          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
